import React, { Fragment } from "react"
import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    Hey there, I'm melo!
    <br />
    Feel free to check out who I am{" "}
    <Styled.a href="https://melo.myds.me/">in my CV</Styled.a>.
  </Fragment>
)
